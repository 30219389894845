<template>
  <InvoiceList type="INVOICE"/>
</template>

<script>
import InvoiceList from "@/views/erp/InvoiceList.vue";

export default {
  components: {
    InvoiceList,
  }
}
</script>
